import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { observer } from 'mobx-react-lite';
import { Box, makeStyles, CircularProgress } from '@material-ui/core';

import ProductListItem from './ProductListItem';
import { useShopStore } from '~/domains';
import { ProductInterface, ProductListItemInterface } from '~/domains/product';


const useStyle = makeStyles(() => ({
  loader: {
    textAlign: 'center',
  },
}));

interface ProductListPropTypes {
  listKey: string;
}

const ProductList: React.FC<ProductListPropTypes> = observer(({
  listKey,
}: ProductListPropTypes) => {
  const {
    productListStore: {
      getProductList,
      fetchNextList,
    },
  } = useShopStore();
  const productList = getProductList(listKey);
  if (!productList) return null;
  const classes = useStyle();
  const { items: products , hasMore } = productList;
  const [scrollY, setScollY] = useState(0);

  const handleNextFetch = async () => {
    await fetchNextList(listKey) as ProductInterface[];
  };

  const handleScroll = () => {
    setScollY(window.scrollY);
  };

  return (
    <Box>
      <InfiniteScroll
        dataLength={products.length}
        next={handleNextFetch}
        hasMore={hasMore}
        loader={
          <Box className={classes.loader}>
            <CircularProgress color="primary" />
          </Box>
        }
        onScroll={handleScroll}
      >
        {
          products.map(({
            product,
            isImpress,
            checkImpression,
          }: ProductListItemInterface,
          index: number,
          ) => (
            <ProductListItem
              product={product}
              position={index + 1}
              isImpress={isImpress}
              scrollY={scrollY}
              checkImpression={checkImpression}
              key={product._id}
            />
          ))
        }
      </InfiniteScroll>
    </Box>
  );
});

export default ProductList;
