import color from './color';
import red from '@material-ui/core/colors/red';

const palette = {
  primary: {
    main: color.green[600],
  },
  secondary: {
    main: color.blue[600],
  },
  error: {
    main: red.A400,
  },
  background: {
    default: color.backgroundColor,
  },
  grey: color.grey,
};

export default palette;
