import React, { useEffect } from 'react';

interface NotSupportedImagePropType {
  className?: string;
  width?: string;
  height?: string;
  color?: string;
  onLoad?: () => void;
}

const NotSupportedImage: React.FC<NotSupportedImagePropType> = ({
  className,
  width = '4.2rem',
  height,
  color = '#bdbdbd',
  onLoad,
}) => {
  useEffect(() => {
    if(onLoad) onLoad();
  }, []);

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      fill={color}
      width={width || height}
      height={height || width}
    >
      <g>
        <rect fill="none" height="24" width="24"/>
        <path d="M21.9,21.9l-8.49-8.49l0,0L3.59,3.59l0,0L2.1,2.1L0.69,3.51L3,5.83V19c0,1.1,0.9,2,2,2h13.17l2.31,2.31L21.9,21.9z M5,18 l3.5-4.5l2.5,3.01L12.17,15l3,3H5z M21,18.17L5.83,3H19c1.1,0,2,0.9,2,2V18.17z"/>
      </g>
    </svg>
  );
};

export default NotSupportedImage;
