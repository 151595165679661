import React from 'react';
import { getSnapshot } from 'mobx-state-tree';
import { GetServerSideProps } from 'next';

import HttpError from '~/utils/HttpError';
import { initializeStore } from '~/domains';
import { getProductsAction } from '~/reducers';
import { trackEvent, ProductsApiQuery } from '~/utils';
import { Layout } from '~/components/layout';
import { ProductList } from '~/components/productList';
import { ShopStoreInterface } from '~/domains/shop/stores';
import { PER_PAGE } from '~/constant/pagination';

const Products: React.FC = () => {
  const trackGoBackEvent = () => {
    trackEvent('productList', 'goBack');
  };
  const trackCloseEvent = () => {
    trackEvent('productList', 'close');
  };

  return (
    <Layout
      title="스토어"
      trackGoBackEvent={trackGoBackEvent}
      trackCloseEvent={trackCloseEvent}
    >
      <ProductList listKey="available" />
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps = async () => {
  const store: ShopStoreInterface = initializeStore();

  const query: ProductsApiQuery = {
    page: 1,
    limit: PER_PAGE,
    available: true,
  };

  try {
    await store.productListStore.loadProducts(query, 'available');
    const initialState = getProductsAction(getSnapshot(store));

    return { props: { initialState } };
  } catch (err) {
    throw new HttpError(err.message, err.status);
  }
};

export default Products;
