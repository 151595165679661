import { createMuiTheme } from '@material-ui/core/styles';
import overrides from './overrides';
import palette from './palette';
import typography from './typography';
import spacing from './spacing';

// NOTE: createMuiTheme orverride tpye error 제거
const theme = (createMuiTheme as any)({
  overrides,
  palette,
  typography,
  spacing,
});

export default theme;
