import color from './color';
import spacing from './spacing';

const overrides = {
  MuiButton: {
    root: {
      ['&:hover']: {
        backgroundColor: 'auto',
      },
      ['&$disabled']: {
        background: color.grey[400],
        color: '#fff',
      },
      '&$outlined': {
        border: `solid 1px ${color.green[600]}`,
        color: color.green[600],
        fontSize: '1.4rem',
        lineHeight: 1.5,
        fontWeight: 500,
        '&$disabled': {
          border: `solid 1px ${color.grey[400]}`,
          color: color.grey[400],
          background: 'transparent',
        },
      },
      '&$contained': {
        backgroundColor: color.green[600],
        color: '#fff',
        fontSize: '1.4rem',
        lineHeight: 1.5,
        fontWeight: 500,
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: '#00B88A',
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: color.green[600],
        },
        '&$disabled': {
          backgroundColor: color.grey[400],
        },
      },
    },
  },
  MuiContainer: {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
      ['@media (min-width: 600px)']: {
        padding: 0,
      },
    },
  },
  MuiTypography: {
    root: {
      color: color.grey[900],
    },
  },
  MuiSvgIcon: {
    root: {
      color: color.grey[600],
      fontSize: '2.4rem',
      height: `${spacing(3)}rem`,
      width: `${spacing(3)}rem`,
    },
  },
  MuiExpansionPanel: {
    root: {
      boxShadow: 'none',
      ['&:before']: {
        backgroundColor: 'none',
      },
      ['&.Mui-expanded']: {
        marginTop: 0,
        marginBottom: 0,
      },
      ['& .MuiExpansionPanelSummary-root']: {
        padding: `0 ${spacing(2)}rem`,
        height: `${spacing(7)}rem`,
        minHeight: `${spacing(7)}rem`,
        ['&.Mui-expanded']: {
          height: `${spacing(7)}rem`,
          minHeight: `${spacing(7)}rem`,
          borderBottom: `1px solid ${color.grey[200]}`,
        },
      },
      ['& .MuiExpansionPanelSummary-content']: {
        marginTop: 0,
        marginBottom: 0,
        ['&.Mui-expanded']: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
      ['& .MuiIconButton-root']: {
        padding: 0,

        ['&.MuiIconButton-edgeEnd']: {
          marginRight: 0,
        },
      },
      ['& .MuiExpansionPanelDetails-root']: {
        padding: `${spacing(2)}rem ${spacing(2)}rem ${spacing(3)}rem ${spacing(2)}rem`,
        borderBottom: `1px solid ${color.grey[200]}`,
      },
    },
  },
  MuiDivider: {
    root: {
      backgroundColor: color.grey[200],
    },
  },
  MuiOutlinedInput: {
    root: {
      width: '100%',
      '&$focused $notchedOutline': {
        borderColor: color.green[600],
        borderWidth: 1,
      },
      '&$error $notchedOutline': {
        borderColor: color.error,
      },
      '&$disabled $notchedOutline': {
        display: 'none',
      },
      '&$disabled input': {
        border: `solid 1px ${color.grey[400]}`,
        borderRadius: '4px',
        backgroundColor: color.grey[50],
        '&::placeholder': {
          color: color.grey[400],
        },
      },
      '& $notchedOutline': {
        borderColor: color.grey[400],
      },
    },
    input: {
      lineHeight: 'normal',
      padding: `0 ${spacing(2)}rem`,
      height: `${spacing(7)}rem`,
      boxSizing: 'border-box',
      fontSize: '1.6rem',
      color: color.grey[900],
      '&::placeholder': {
        color: color.grey[500],
      },
    },
  },
  MuiFormHelperText: {
    root: {
      lineHeight: 1.5,
      fontSize: '1.2rem',
      margin: 0,
      marginTop: `${spacing(0.5)}rem`,
      '&$contained': {
        margin: 0,
        marginTop: `${spacing(0.5)}rem`,
      },
      '&$error': {
        color: color.error,
      },
    },
  },
  MuiFormGroup: {
    root: {
      '&[role=radiogroup]': {
        flexDirection: 'row',
        '& .MuiFormControlLabel-root': {
          margin: 0,
        },
        '& .MuiFormControlLabel-label': {
          fontSize: '1.4rem',
          lineHeight: 1.5,
          color: color.grey[900],
          marginLeft: `${spacing(0.5)}rem`,
        },
      },
    },
  },
  MuiFormControlLabel: {
    label: {
      color: color.grey[900],
      fontSize: '1.4rem',
      lineHeight: 1.5,
    },
  },
  MuiCheckbox: {
    root: {
      padding: `${spacing()}rem`,
      '&$checked': {
        '& svg': {
          color: color.green[600],
        },
      },
      '& svg': {
        color: color.grey[500],
      },
    },
  },
  MuiRadio: {
    root: {
      padding: 0,
      '& .MuiSvgIcon-root': {
        color: color.grey[400],
      },
      '&$checked': {
        '& .MuiSvgIcon-root': {
          color: color.green[600],
        },
      },
    },
  },
};

export default overrides;
