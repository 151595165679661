import React, { useEffect, useState, useRef } from 'react';
import Link from 'next/link';
import { makeStyles, Box, Typography } from '@material-ui/core';

import { themeColor } from '~/styles/theme';
import { NotSupportedImage } from '~/unit/icons';
import { trackEvent, trackImpression, trackProductDetailMove } from '~/utils';
import { ProductInterface } from '~/domains/product';

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    marginBottom: `${theme.spacing()}rem`,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  thumbnail: {
    '& img': {
      display: 'block',
      width: '100%',
    },
  },
  collection: {
    fontSize: '1.4rem',
    color: themeColor.grey[600],
    marginBottom: `${theme.spacing(0.5)}rem`,
  },
  title: {
    fontWeight: 'bold',
    color: themeColor.grey[900],
    fontSize: '2rem',
    marginBottom: `${theme.spacing()}rem`,
  },
  subtitle: {
    color: themeColor.grey[800],
    fontSize: '1.5rem',
  },
  contentWrap: {
    padding:
      `${theme.spacing(3)}rem ${theme.spacing(2)}rem ` +
      `${theme.spacing(4)}rem ${theme.spacing(2)}rem`,
    fontSize: '1.5rem',
  },
  errorImageWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.grey[200],
    width: '100%',
    height: '27rem',
  },
}));

interface ProductListItemPropTypes {
  product: ProductInterface;
  position: number;
  isImpress: boolean;
  scrollY: number;
  checkImpression: (bottomPosition: number, topPosition: number, viewHeight: number, umImpressRate?: number) => boolean;
}

const ProductListItem: React.FC<ProductListItemPropTypes> = ({
  product,
  position,
  isImpress,
  scrollY,
  checkImpression,
}) => {
  const classes = useStyle();
  const [isImageError, setIsImageError] = useState(false);
  const itemEl = useRef(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleErrorImage = () => {
    setIsImageError(true);
  };

  const handleClickThumbnail = () => {
    trackEvent('productList', 'productThumbnail', () => {
      trackProductDetailMove(product, 'home');
    });
  };

  const handleClickTextContent = () => {
    trackEvent('productList', 'productText', () => {
      trackProductDetailMove(product, 'home');
    });
  };

  const isSupportedImage = (product.thumbnail && !isImageError);

  const handleImageLoaded = () => {
    setIsImageLoaded(true);
  };

  useEffect(() => {
    if(!isImpress) {
      if (isImageLoaded) {
        const el = itemEl.current as HTMLElement | null;
        if (el) {
          const { bottom, top } = el.getBoundingClientRect();
          const viewHeight = window.innerHeight || document.documentElement.clientHeight;

          if(checkImpression(bottom, top, viewHeight)) {
            trackImpression(product, 'home', position);
          }
        }
      }
    }
  }, [isImpress, scrollY, isImageLoaded, itemEl.current]);

  return (
    <div className={classes.root} ref={itemEl}>
      <Link href="/products/[id]" as={`/products/${product._id}`}>
        <Box>
          <Box onClick={handleClickThumbnail}>
            {
              isSupportedImage
                ? (
                  <figure className={classes.thumbnail}>
                    <img
                      src={product.thumbnail?.url ?? ''}
                      onError={handleErrorImage}
                      onLoad={handleImageLoaded}
                    />
                  </figure>
                )
                : (
                  <Box className={classes.errorImageWrap}>
                    <NotSupportedImage onLoad={handleImageLoaded} />
                  </Box>
                )
            }
          </Box>
          <Box className={classes.contentWrap} onClick={handleClickTextContent}>
            {product.collection && (
              <Typography className={classes.collection}>
                {product.collection.name}
              </Typography>
            )}
            <Typography className={classes.title}>{product.titleAtList}</Typography>
            <Typography className={classes.subtitle}>
              {product.subtitleAtList}
            </Typography>
          </Box>
        </Box>
      </Link>
    </div>
  );
};

export default ProductListItem;
